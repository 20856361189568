@font-face {
  font-family: "HeartBreakingBad";
  src: url(fonts/heart-breaking-bad-font/HeartBreakingBad-zG0w.ttf)
    format("truetype");
}
@font-face {
  font-family: "BreakingBad";
  src: url(fonts/breaking_bad/BREAKINGBAD.ttf) format("truetype");
}
@font-face {
  font-family: "Quicksand";
  src: url(fonts/Quicksand/Quicksand-VariableFont_wght.ttf) format("truetype");
}

:root {
  --black: rgb(3, 34, 2);
  --white: rgb(252, 248, 248);
  --dark-green-900: rgb(9, 48, 9);
  --dark-green-700: rgb(13, 62, 16);
  --green-900: rgb(31, 96, 50);
  --green-500: rgb(41, 119, 62);
  --green-200: rgb(54, 148, 87);
}

*,
*::before,
*::after {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  min-height: 100vh;
  background-color: var(--black);
  color: var(--white);
  /* font-family: Arial, Helvetica, sans-serif; */
  font-family: "Quicksand", sans-serif;
}

.item-links {
  width: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  /* color: rgba(41, 119, 62, 0.5); */
  color: var(--white);
  background-color: var(--green-200);
  border-radius: 0.5em;
  margin: 0.5em auto;
  padding: 0.25em;

  transition: 0.5s ease-in-out;
}

.item-links:hover,
.item-links:active {
  color: var(--green-200);
  background-color: var(--green-900);
}

a {
  color: var(--white);
  text-decoration: none;

  transition: 0.5s ease-in-out;
}

a:hover,
a:active {
  text-decoration: none;
  color: var(--green-200);
}

.info {
  color: var(--green-200);

  transition: 0.5s ease-in-out;
}

.info:hover,
.info:active {
  color: var(--white);
}

.container {
  position: relative;
  height: 85vh;
  max-width: 1200px;
  margin: auto;
  padding: 0 20px;
  padding-top: 1em;
  background-image: url("./img/bg.webp");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
}

.btn {
  display: inline-block;
  color: var(--white);
  background-color: var(--green-200);
  font-size: 1em;
  text-align: center;
  padding: 10px 15px;
  border: 0;
  margin: 10px 0;
}

header {
  height: 15vh;
  width: 100%;
}

main {
  height: 75vh;
}

footer {
  height: 10vh;
  width: 100%;
  background-color: var(--green-200);
  color: var(--white);
  text-align: center;
  padding: 10px 0;
}

header img {
  display: flex;
  width: 200px;
}

header p {
  display: flex;
  width: 200px;
}
header img {
  display: flex;
  width: 200px;
}

.header-link {
  font-family: "BreakingBad";
  font-size: 5em;
  background: linear-gradient(
    to right,
    var(--dark-green-700) 0%,
    var(--green-200) 50%,
    var(--dark-green-700) 100%
  );
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  cursor: pointer;
}

.header-link span .text-gradient,
.nav-link span .text-gradient {
  font-family: "BreakingBad";
  font-size: 2.5em;
  transition: 0.5s ease-in-out;
  background: linear-gradient(
    to right,
    var(--dark-green-700) 0%,
    var(--green-200) 50%,
    var(--dark-green-700) 100%
  );
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.nav-link p .text-normal {
  font-family: "Quicksand";
}

nav.navbar .navbar-nav {
  position: relative;
  display: flex;
  list-style: none;
  text-decoration: none;
}
.nav-link {
  position: relative;
  height: auto;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 0.5em;
  padding-left: 1em;
  color: var(--white);

  /* font-family: "HeartBreakingBad"; */
  font-size: 2em;
}

.nav-link span {
  font-family: "BreakingBad";
  /* color: rgba(41, 119, 62, 0.65); */
  font-size: 2.5em;
  transition: 0.5s ease-in-out;
  background: linear-gradient(
    to bottom right,
    var(--dark-green-700) 0%,
    var(--green-200) 50%,
    var(--dark-green-700) 100%
  );
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.nav-link:hover span,
.nav-link:active span {
  /* font-family: "HeartBreakingBad"; */
  transform: scale(0.9);
  /* font-size: 2em; */
  background: linear-gradient(
    to bottom right,
    var(--green-200) 0%,
    var(--white) 50%,
    var(--green-200) 100%
  );
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.center {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 auto;
}

.search {
  height: 100px;
}

form {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

input[type="text"] {
  display: block;
  padding: 10px;
  font-size: 20px;
  border: 0;
  border-radius: 5px;
  width: 60%;
  margin: auto;

  color: var(--green-500);
}

.season-cards {
  height: 80vh;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 1rem;
}

.cards {
  height: 80vh;
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 1rem;
}

.card {
  /* cursor: pointer; */
  background-color: transparent;
  height: 300px;
  perspective: 1000px;
}

.card h1 {
  font-size: 25px;
  border-bottom: 1px var(--white) solid;
  padding-bottom: 10px;
  margin-bottom: 10px;
}

.card-front h5 {
  position: absolute;
  height: 2.5vh;
  width: 100%;
  top: 50%;
  left: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  transform: translate(-50%, -50%);

  font-size: 25px;
  text-align: center;
  background-color: rgba(9, 48, 9, 0.62);
  /* color: var(--white); */
  /* background: linear-gradient(
    to right bottom,
    var(--green-200) 40%,
    var(--black) 50%,
    var(--green-200) 60%
  );
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent; */
}

.card img {
  width: 100%;
  height: 300px;
  object-fit: cover;

  box-shadow: 2px 2px 6px 0px rgba(117, 225, 108, 0.62);
  -webkit-box-shadow: 2px 2px 6px 0px rgba(117, 225, 108, 0.62);
  -moz-box-shadow: 2px 2px 6px 0px rgba(117, 225, 108, 0.62);
}

.card-inner {
  position: relative;
  width: 100%;
  height: 100%;
  transition: transform 0.8s;
  transform-style: preserve-3d;
}

.card:hover .card-inner {
  transform: rotateY(180deg);
}

.card-front,
.card-back {
  position: absolute;
  width: 100%;
  height: 100%;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
}

.episode-single-container {
  height: 80vh;
  display: flex;
  flex-direction: column;
}

.character-single-container {
  width: 100%;
  height: 100vh;
}

.character-single {
  width: 100%;
  height: 100%;
  display: flex;
}

.episode-single-inner {
  /* cursor: pointer; */
  background-color: transparent;
  height: 80vh;
  perspective: 1000px;
}

.character-single-inner {
  width: 100%;
  height: 100%;
  display: flex;
  background-color: transparent;
}

.character-single-left {
  width: 50%;
  height: 100%;
  background-color: transparent;
}

.character-single-right {
  width: 50%;
  height: 100%;
  background-color: transparent;

  padding: 1em;
}

.episode-single-inner li,
.character-single-inner li {
  list-style: none;
}

.episode-single-inner h1,
.character-single-inner h1 {
  font-size: 25px;
  border-bottom: 1px var(--white) solid;
  padding-bottom: 10px;
  margin-bottom: 10px;
}

.character-single-inner p {
  font-size: 1em;
  padding-bottom: 10px;
  margin-bottom: 10px;
}

.episode-single img {
  width: 100%;
  height: 20vw;
  object-fit: contain;

  margin: 1em auto;
}

.character-single img {
  width: 100%;
  height: 78vh;
  object-fit: contain;

  margin: 1em auto;
}

.notice-single-inner {
  width: 50%;
  margin: 0 auto;
}

.notice-single {
  height: 35vh;

  display: flex;
  align-items: center;
  justify-content: space-around;
}

.notice-single h1 {
  border-bottom: 1px solid var(--white);
}

.card-back {
  background-color: #333;
  color: var(--white);
  padding: 20px;
  transform: rotateY(180deg);

  box-shadow: 2px 2px 6px 0px rgba(117, 225, 108, 0.62);
  -webkit-box-shadow: 2px 2px 6px 0px rgba(117, 225, 108, 0.62);
  -moz-box-shadow: 2px 2px 6px 0px rgba(117, 225, 108, 0.62);
}

.card li {
  list-style: none;
  padding-bottom: 10px;
}

.count {
  display: flex;
  align-items: center;
  justify-content: center;
  color: var(--green-500);
  padding-bottom: 6px;
  background: linear-gradient(
    to bottom right,
    var(--dark-green-700) 0%,
    var(--green-200) 50%,
    var(--dark-green-700) 100%
  );
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.footer {
  position: fixed;
  height: 5vh;
  width: 100%;
  left: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  background: linear-gradient(
    to right bottom,
    var(--green-500),
    var(--dark-green-900)
  );
  color: var(--white);
  text-align: center;
}

.footer p {
  padding: 0 1em;
}

.icon {
  font-size: 1.5em;
  color: var(--white);

  border-radius: 50%;
  border: none;
  transition: 0.5s ease-in-out;
}

.icon:hover,
.icon:active {
  font-size: 1.5em;
  color: var(--dark-green-700);
  background-color: var(--white);

  border-radius: 50%;
  border: none;
}

button.scroll {
  position: fixed;
  left: 80%;
  bottom: 10vh;
  height: 20px;
  font-size: 3rem;
  color: var(--green-500);
  background-color: transparent;
  border: none;

  cursor: pointer;
  z-index: 1;
}

@media (max-width: 800px) {
  .cards {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media (max-width: 500px) {
  .cards,
  .season-cards {
    grid-template-columns: 1fr;
  }
  header {
    display: flex;
    flex-direction: column;
    height: 30vh;
  }
  nav.navbar .navbar-nav {
    padding-top: 1em;
    font-size: 0.75em;
  }
  .episode-single img {
    width: 100%;
    height: 40vw;
    object-fit: cover;

    margin: 0.5em auto;
  }
  .character-single-left,
  .character-single-right {
    width: 100%;
    height: 100%;
    background-color: transparent;
  }

  .character-single {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
  }
  .character-single img {
    width: 100%;
    height: 80vw;
    object-fit: fill;

    margin: 0.5em auto;
  }
  .nav-link {
    padding: 0 0.25em;
    padding-left: 0.5em;
    font-family: "HeartBreakingBad";
    font-size: 1.5em;
  }
  .notice-single-inner {
    height: 100%;
    width: 100%;
    margin: 0 auto;
  }
}
::-webkit-scrollbar-thumb {
  background-image: linear-gradient(
    to right top,
    var(--dark-green-700),
    var(--green-200)
  );
}
::-webkit-scrollbar {
  width: 8px;
  height: 0px;
  /* height 0:  hide the horizontal scrollbar */
}
